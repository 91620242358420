/* stylelint-disable-next-line selector-id-pattern */
#root_geo_data__title {
  padding-left: 15px;
  padding-right: 15px;
}

.property-edit-form {
  .tax-block {
    border-bottom: 4px solid #d6d9da;
    margin-bottom: 50px;
    padding-bottom: 30px;
  }

  .gstin-block {
    margin-bottom: 10px;
    width: 40%;
  }
}
