.drop-zone {
  cursor: pointer;
  width: 100%;
  padding: 20px;
  border: 1px dashed $gray-light;

  &.active {
    color: $state-success-text;
    background-color: $state-success-bg;
    border: 1px dashed $state-success-border;
  }

  &.reject {
    color: $state-danger-text;
    background-color: $state-danger-bg;
    border: 1px dashed $state-danger-border;
  }
}

.images-group {
  .panel-body {
    padding: 0;
  }

  .panel-footer {
    padding: 10px 15px;
  }

  .image-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .image-title-input {
    display: block;
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    margin: 0 10px;
  }
}

.image-note {
  font-size: 14px;
}