.upload-image {
  display: flex;

  .select-image-button {
    display: flex;
    cursor: pointer;
    height: 100%;
    width: 215px;
    text-align: center;
    padding: 5px;
    border: 1px dashed #666;
    justify-content: center;
    align-items: center;
  }

  .select-image-block {
    display: inline-block;
    margin: 0 10px;
  }

  .preview-image-block {
    display: inline-block;
  }

  .preview-image-img {
    height: 100%;
    width: 215px;
    text-align: center;
    padding: 5px;
    border: 1px solid #666;
  }

  .select-image-button button {
    color: #575554;
    text-decoration: none;
    background-color: white;
    border-color: #d6d9da;
    border-radius: 4px;
  }
}