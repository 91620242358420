.package-prices {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  background-color: $gray-lighter;

  .package-prices-item {
    position: relative;
    border: 1px solid $ui-gray;
    margin-left: -1px;
    margin-top: -1px;
    padding: 20px;
    flex: 1;

    @include breakpoint-down('sm') {
      padding: 10px;
      flex-basis: calc(16.6667% + 1px);
    }

    dl {
      margin-bottom: 0;
    }
  }
}

.packages-list {
  .package {
    position: relative;
    border-bottom: 1px dotted #c0c0c0;
    margin-top: 30px;

    span {
      padding-bottom: 10px;
      white-space: pre-wrap;
      font-size: 120%;
      font-weight: bolder;
      margin-top: 10px;
    }
  }
}

.countries-span {
  padding-right: 8px;
}

.packages-errors {
  color: $brand-danger;
}

.package-edit {
  /* stylelint-disable-next-line selector-class-pattern */
  .MuiGrid-root > .MuiGrid-item {
    padding-top: 8px;
  }
}