.radio-datepicker {
  margin: 23px 0;

  .radio-datepicker-pane {
    border: 1px solid #d6d9da;
    background-color: #fff;
  }

  .month-wrapper {
    border-top: 1px solid #ecefef;

    &:last-child {
      border-bottom: 1px solid #ecefef;
    }
  }

  .month-body {
    border-right: 1px solid #ecefef;
    border-left: 1px solid #ecefef;
  }

  .date {
    padding: 1.875rem 2.875rem;
    font-size: 1.8rem;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-width: 0.125rem;
    margin-bottom: 0;
    font-weight: normal;
    max-width: auto;

    + .date {
      border-top: 1px solid #ecefef;
    }

    &.disabled {
      background-color: #f9f9f9;
      cursor: not-allowed;
    }
  }

  .radio-button {
    width: 2rem;
    height: 2rem;
    position: relative;
    border: 1px solid #d6d9da;
    border-radius: 100%;
    flex-shrink: 0;

    + * {
      margin-left: 0.5rem;
    }
  }

  .start-date-text,
  .end-date-text {
    margin-left: 1.5625rem;
  }

  .start-date-text {
    color: #00587c;
  }

  .end-date-text {
    color: #999;
  }

  .icon-arrow {
    display: flex;
    align-items: center;
    min-width: 6.25rem;

    hr {
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      border: 0;
      border-top: 1px solid #d6d9da;
    }

    &::after {
      width: 6px;
      height: 6px;
      margin-left: -0.5rem;
      content: '';
      border-top: 1px solid #cacaca;
      border-right: 1px solid #cacaca;
      transform: rotate(45deg);
    }
  }

  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    outline: none;
    opacity: 0;
    font-size: 1.8rem;

    & + i {
      display: inline-block;
      pointer-events: none;
      background-color: #00a3ad;
      border-radius: 100%;
      transition: all 0.32s cubic-bezier(0.37, 0.99, 0.74, 1);
      transform: scale(0);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      cursor: pointer;
      outline: none;
      opacity: 0;
    }

    &:checked + i {
      opacity: 1;
      transform: scale(0.6);
    }
  }
}
