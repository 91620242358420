@font-face {
  font-family: 'Suisse Works';
  src: url('../assets/fonts/SuisseWorks-BookItalic.woff2') format('woff2');
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}

// Regular
@font-face {
  font-family: 'Suisse Intl';
  src: url('../assets/fonts/SuisseIntl-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Book
@font-face {
  font-family: 'Suisse Intl';
  src: url('../assets/fonts/SuisseIntl-Book.woff2') format('woff2');
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}

// Medium
@font-face {
  font-family: 'Suisse Intl';
  src: url('../assets/fonts/SuisseIntl-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// SemiBold
@font-face {
  font-family: 'Suisse Intl';
  src: url('../assets/fonts/SuisseIntl-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
