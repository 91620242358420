.dl-horizontal {
  &.sm,
  &.md {
    margin-bottom: 15px;

    dt {
      text-align: left;
      float: left;
    }
  }

  &.sm {
    dt {
      width: 50px;
    }

    dd {
      margin-left: 60px;
    }
  }

  &.md {
    dt {
      width: 120px;
    }

    dd {
      margin-left: 130px;
    }
  }
}

dt.h6 {
  margin-bottom: 5px;
}

.capitalize {
  text-transform: capitalize;
}