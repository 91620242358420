/* stylelint-disable-next-line selector-class-pattern */
a.MuiButton-contained:hover {
  color: white;
}

.btn {
  + .secondary {
    margin-left: 20px;
  }
}

.btn-half {
  width: 45%;
}

.button-container {
  margin-top: 20px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 15px;
  z-index: 200;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;

  &.right {
    background-color: transparent;
    justify-content: right;
    margin-right: 15px;
    width: 50%;
    float: right;
  }
}

.stripe-custom-button {
  display: inline-block;
  background-image: linear-gradient(#28a0e5, #015e94);
  -webkit-font-smoothing: antialiased;
  border: 0;
  padding: 1px;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 20%);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 20%);
  -ms-box-shadow: 0 1px 0 rgba(0, 0, 0, 20%);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 20%);
}

.stripe-custom-button span {
  display: block;
  position: relative;
  padding: 0 12px;
  height: 30px;
  line-height: 30px;
  background: #1275ff;
  background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 25%);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 25%);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 25%);
  -ms-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 25%);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 25%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.rounded-button {
  border-radius: 30px;
  padding: 1rem 2rem;
}

.badges-btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 36px;
  border-radius: 2px;
  width: 128px;
  border: none;
  background: transparent;
  color: #0697ff;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  &:focus,
  &:hover {
    color: initial;
    background-color: transparent;
    outline: none;
  }
}

.outlined {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .badges-btn;

  border: 1px solid;
  border-color: #0697ff;

  &:focus,
  &:hover {
    border-color: #0697ff;
  }
}

.remove {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .badges-btn;

  color: red;
}
