.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon-ae {
  background-image: url(../assets/flags/4x3/ae.svg);
}
.flag-icon-au {
  background-image: url(../assets/flags/4x3/au.svg);
}
.flag-icon-ca {
  background-image: url(../assets/flags/4x3/ca.svg);
}
.flag-icon-cn {
  background-image: url(../assets/flags/4x3/cn.svg);
}
.flag-icon-de {
  background-image: url(../assets/flags/4x3/de.svg);
}
.flag-icon-es {
  background-image: url(../assets/flags/4x3/es.svg);
}
.flag-icon-fr {
  background-image: url(../assets/flags/4x3/fr.svg);
}
.flag-icon-gb {
  background-image: url(../assets/flags/4x3/gb.svg);
}
.flag-icon-hk {
  background-image: url(../assets/flags/4x3/hk.svg);
}
.flag-icon-id {
  background-image: url(../assets/flags/4x3/id.svg);
}
.flag-icon-ie {
  background-image: url(../assets/flags/4x3/ie.svg);
}
.flag-icon-il {
  background-image: url(../assets/flags/4x3/il.svg);
}
.flag-icon-in {
  background-image: url(../assets/flags/4x3/in.svg);
}
.flag-icon-it {
  background-image: url(../assets/flags/4x3/it.svg);
}
.flag-icon-jp {
  background-image: url(../assets/flags/4x3/jp.svg);
}
.flag-icon-kr {
  background-image: url(../assets/flags/4x3/kr.svg);
}
.flag-icon-mo {
  background-image: url(../assets/flags/4x3/mo.svg);
}
.flag-icon-my {
  background-image: url(../assets/flags/4x3/my.svg);
}
.flag-icon-nz {
  background-image: url(../assets/flags/4x3/nz.svg);
}
.flag-icon-ph {
  background-image: url(../assets/flags/4x3/ph.svg);
}
.flag-icon-qa {
  background-image: url(../assets/flags/4x3/qa.svg);
}
.flag-icon-ru {
  background-image: url(../assets/flags/4x3/ru.svg);
}
.flag-icon-sa {
  background-image: url(../assets/flags/4x3/sa.svg);
}
.flag-icon-sg {
  background-image: url(../assets/flags/4x3/sg.svg);
}
.flag-icon-th {
  background-image: url(../assets/flags/4x3/th.svg);
}
.flag-icon-tw {
  background-image: url(../assets/flags/4x3/tw.svg);
}
.flag-icon-us {
  background-image: url(../assets/flags/4x3/us.svg);
}
.flag-icon-za {
  background-image: url(../assets/flags/4x3/za.svg);
}
.flag-icon-un {
  background-image: url(../assets/flags/4x3/un.svg);
}
.flag-icon-vn {
  background-image: url(../assets/flags/4x3/vn.svg);
}
