.badges-list {
  display: flex;
  align-content: center;

  .badge-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    width: 170px;
    height: 170px;
    padding: 8px;

    &:not(:first-child) {
      margin-left: 16px;
    }

    .cover {
      width: 130px;
      height: 130px;
      margin-bottom: 8px;
    }
  }
}
