/* stylelint-disable scss/no-global-function-names */
$font-size-base: 14px !default;

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee

$brand-primary: #00a0f5;
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-muted: $gray-light;

$title-highligts: #ff4200;
$title-edit-link: $brand-info;
$ui-gray: #d8d8d8;
$text-gray: $gray;
$text-active-gray: $gray-dark;
$completed-state-color: $brand-primary;

$default-font-weight: 300;

$warning: #f5d22b;
$success: #21d37e;

$womens-day-spanish-violet: #46296e;

$state-success-text: #3c763d !default;
$state-success-bg: #dff0d8 !default;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text: #31708f !default;
$state-info-bg: #d9edf7 !default;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text: #8a6d3b !default;
$state-warning-bg: #fcf8e3 !default;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text: #a94442 !default;
$state-danger-bg: #f2dede !default;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%) !default;

$panel-inner-border: #ddd !default;
$panel-heading-padding: 10px 15px !default;
$panel-default-border: #ddd !default;

$line-height-base: 1.428571429 !default; // 20/14
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~20px

$table-border-color: #ddd !default;
