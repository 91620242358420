.booking-form {
  .booking-form-row {
    display: flex;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }

  .booking-form-item {
    flex: 1;
    display: flex;
    flex-direction: column;

    & + .booking-form-item {
      margin-left: 10px;
    }

    label {
      display: block;
    }

    input[type='text'] {
      width: 100%;
    }
  }

  .booking-input,
  .booking-select {
    border: 1px solid #e0e0e0;
    padding: 13px 17px;
    font-weight: 500;

    &:disabled {
      cursor: not-allowed;
      background-color: #fbfbfb;
      color: #757575;
      text-align: left;
    }
  }

  .booking-select {
    padding: 6px 17px;
    height: 50px;
  }

  .travel-dates {
    flex: 1;
    display: flex;
    cursor: pointer;

    &:not(.disabled):hover {
      background: #fbfbfb;
    }

    &.disabled {
      background: #fbfbfb;
      color: #757575;
      cursor: not-allowed;
    }

    .date-label {
      flex: 1;
    }

    .datepicker-trigger {
      background: none;
      border: none;
      padding: 0 13px;
      color: #0097ec;

      &:disabled {
        color: #757575;
        cursor: not-allowed;
      }
    }
  }

  .guestpicker-container {
    display: inline-block;
    position: relative;
    min-width: 220px;
  }

  .guestpicker-trigger {
    background: none;
    width: 100%;

    &:not(:disabled):hover {
      background: #fbfbfb;
    }
  }

  .guestpicker-options {
    position: absolute;
    background-color: white;
    width: 100%;
    padding: 13px 21px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 17.5%);
  }

  .guestpicker-options-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 11px;
    }
  }

  .guest-count {
    margin: 0 12px;
    vertical-align: middle;
    font-weight: 500;
  }

  .quantity-btn {
    border: 1px solid #039ff5;
    display: inline-block;
    background: none;
    border-radius: 100%;
    color: #039ff5;
    width: 33px;
    font-size: 20px;
    vertical-align: middle;

    & + .quantity-btn {
      margin-left: 13px;
    }

    &:disabled {
      color: #bce5fb;
      border-color: #bce5fb;
      cursor: not-allowed;
    }
  }

  .quantity-value {
    width: 59px;
    display: inline-block;
    margin-right: 24px;
    vertical-align: middle;
    text-align: center;
  }

  .quanity-btn-cell {
    white-space: nowrap;
  }
}
