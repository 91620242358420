// Grids
.grid-4 {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr);
}

// Width & height

.w-half {
  width: 50%;
}

.w-20 {
  width: 20px;
}

.h-20 {
  height: 20px;
}

.w-24 {
  width: 24px;
}

.h-24 {
  height: 24px;
}

//== Margin Helpers
//##

.nm {
  margin: 0;
}

.nmt {
  margin-top: 0;
}

.nmr {
  margin-right: 0;
}

.nmb {
  margin-bottom: 0;
}

.nml {
  margin-left: 0;
}

.ma {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

//== Padding Helpers
//##

.np {
  padding: 0;
}

.npt {
  padding-top: 0;
}

.npr {
  padding-right: 0;
}

.npb {
  padding-bottom: 0;
}

.npl {
  padding-left: 0;
}

.spx {
  &.s10 {
    margin-left: 10px;
    margin-right: 10px;
  }
}
