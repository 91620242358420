.update-alert-container {
  position: sticky;
  top: 0;
  text-align: center;
  width: 100%;
  transform: translateY(0);
  transition: transform 500ms ease-out;
  z-index: 1000;

  &.offscreen {
    transform: translateY(-300px);
    height: 0;
  }
}
