.offer-list-order-page {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .justified-container {
    margin-bottom: 4px;
  }
}

.offer-list-title {
  margin-top: 0;
}

.offer-default-button {
  color: #333;
  margin-right: 5px;
}

.offer-default-button-disabled {
  color: #a9a9a9;
}
