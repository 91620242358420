.navbar {
  .navbar-brand {
    padding-top: 19px;
    padding-bottom: 19px;
  }
}

#nav-user-menu {
  background-color: inherit;
  border: none;
  color: white;
  padding: 0 12px;

  > p.navbar-text {
    margin: 0 8px;
  }

  a {
    color: inherit;
  }
}

.navbar-nav {
  > li > a {
    padding: 9px 8px;
    margin-top: 13px;
    margin-bottom: 13px;
    border: 1px solid transparent;

    &.active {
      border-color: $gray;
      color: $gray-lighter;
    }
  }
}

.navbar-nav .disabled {
  pointer-events: none;
}

.navbar-nav div[role='button'] {
  padding: 10px;
  margin: 13px 0;
  display: inline-block;
  line-height: 20px;
}

.lux {
  &.navbar {
    background-color: #222;
  }

  .navbar-nav > li > a {
    color: #777;
  }
}

.womens-day {
  &.navbar {
    color: #fff;
    background-color: $womens-day-spanish-violet;
  }

  .navbar-nav > li > a {
    color: #fff;
  }
}

.cudo {
  &.navbar {
    background: linear-gradient(90deg, rgb(0, 173, 179), rgb(20, 127, 196));
  }
}

.deals {
  &.navbar {
    background: linear-gradient(90deg, rgb(20, 127, 211), rgb(0, 170, 245));
  }
}

.treatme {
  &.navbar {
    background: linear-gradient(90deg, rgb(255, 111, 0), rgb(255, 65, 0));
  }
}

.cudo,
.deals,
.treatme {
  .navbar-nav > li > a {
    color: #fff;
  }
}

.scoopon {
  &.navbar {
    background-color: #eb2f3a;
  }

  .navbar-nav > li > a {
    color: #eee;

    &.active {
      border-color: #ddd;
    }
  }
}

.zoomzoom {
  &.navbar {
    background-color: #222;
  }

  .navbar-nav > li > a {
    color: #ddd;

    &.active {
      border-color: #ddd;
    }
  }
}
