.schedule-search {
  & .schedules-arrow {
    font-size: 2rem;
    position: absolute;
    right: -10px;
  }

  &__filter-dates {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__column {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    color: red;
    cursor: pointer;
    text-decoration: underline;
  }

  &__button:hover {
    color: red;
  }
}

.secound-row {
  margin-bottom: 15px;
}

.availability-search {
  display: flex;
  align-items: center;
}
