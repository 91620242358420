.sortable-amenity-group-list {
  padding: 0; //override user-agent stylesheet
  overflow-y: scroll;

  // 450px is nearly (439) the number of px all other elements take up - give the rest to the list
  height: calc(100vh - 450px);
}

.sortable-amenity-group {
  margin: 2px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 1;
  padding: 12px;
  background-color: #fbfbfb;
  list-style-type: none;
  cursor: move;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.sortable-amenity-group-index {
  width: auto;
  margin-right: 4px;
  font-weight: bold;
}

.sortable-amenity-group-location {
  display: inline-block;
  font-weight: bold;
}

.sortable-amenity-group-link {
  display: inline-flex;
  align-items: center;
}

.sortable-amenity-group-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px;
}

.sortable-amenity-group-inner-left {
  display: flex;
  align-items: center;
}

.sortable-amenity-group-inner-right {
  display: flex;
  align-items: center;
}

.sortable-amenity-group-checkbox {
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0 !important; //hack to override bootstrap specificity
}
