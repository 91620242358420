.promo-codes {
  .expire-button {
    color: $brand-primary;
  }

  .react-bs-container-header {
    position: sticky;
    top: 0;
  }

  .btn-primary {
    width: 150px;
    border-radius: 3px;
  }
}

.expire-promo-modal {
  .modal-text {
    margin-top: 10px;
  }
}

.department-tag {
  background-color: #fea51a;
}

.required {
  color: red;
}
