.availability-day {
  display: flex;
  flex-grow: 1;
  width: 6.4rem;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem 3px;
  margin-right: 1px;
  border: 1px solid rgba(0, 0, 0, 0%);
  transition: background-color 150ms ease-out;

  &:hover {
    cursor: pointer;
    background-color: #eee;
    border-radius: 8px;
  }

  .date {
    font-weight: 400;

    .surcharge-marker {
      color: rgb(59, 180, 59);
    }
  }

  .counts {
    margin-top: 2px;
    font-size: 80%;
    line-height: 1em;
    text-align: center;
  }

  &.selected {
    border: 1px solid #7ca5b7;
    background-color: #b6e7ff;
  }

  &.blackout {

    .counts,
    .date,
    .rate {
      color: #bbb;
      text-decoration: line-through;
    }

    .blackout-marker {
      display: inline-block;
      text-decoration: none;
      margin-left: 3px;
      text-transform: uppercase;
      color: #555;
      font-size: 10px;
      font-weight: bolder;
    }

    .no-invertory {
      color: #555;
      font-weight: bolder;
    }
  }

  &.surcharge {
    .date {
      padding-left: 0.6em;
    }
  }
}

.selection {
  position: relative;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selection-border {
  position: absolute;
  border: 2px solid rgba(0, 0, 255, 30%);
  background-color: rgba(0, 0, 255, 10%);
}

.room-type-dates-edit {
  .page-header {
    border-bottom: 0;
  }

  .selection {
    border: 1px solid #ddd;
  }
}

.availability-edit-buttons-outer {
  float: right;
  margin-top: 60px;

  .sticky-element {
    background-color: rgba(255, 255, 255, 90%);
  }
}

.availability-edit-buttons {
  padding: 1.5rem 2rem;
}

.inventory-form {
  a {
    cursor: pointer;
  }
}

.select-mode-form {
  display: inline-block;
  margin-right: 50px;

  & > .form-group > * {
    margin-right: 20px;
  }
}
