.geo-field .MuiGrid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.sell-prices-field .MuiGrid-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

form.add-credit-form {
  [readonly] {
    pointer-events: auto;
  }
}

select#tours-select.form-control {
  background-color: #fff;
}

input.flatpickr-disabled {
  background-color: #eee !important;
}

.form-group {
  margin-bottom: 0;
}

.sp-login-form > div + div {
  margin-top: 12px;
}

.addable-checkbox-group {
  .option-item {
    display: block;

    input {
      margin-right: 6px;
    }

    label {
      font-weight: 400;
    }
  }
}

.le-checkbox {
  display: inline-block;
  width: 38px;
  height: 38px;
  border: 1px solid #e0e0e0;
  background: none;
  vertical-align: middle;

  .tick-icon {
    color: #0197ec;
  }

  &:hover {
    box-shadow: inset 0 0 6px 3px #f5f6f6;
  }
}

.le-checkbox-label {
  cursor: pointer;
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding-left: 11px;
  font-weight: 500;
  vertical-align: middle;
}

.magic-search-form {
  width: 100%;
}

.center-text {
  text-align: center;
}

.hidden-form-field {
  display: none;
}

.modal-regions-list {
  columns: 2;
}

.region-tags-list {
  columns: 4;
}

.regions-tag-container {
  padding-bottom: 16px;
}

.select-button-container {
  padding-top: 16px;
  display: flex;
  align-items: center;

  .btn-link {
    cursor: pointer;
  }

  .multiple-select-button {
    padding: 5px 10px;
    border-radius: 5px;
    border: 0;
  }
}

.regions-checkbox-label,
.brands-checkbox-label {
  padding-left: 10px;
}

/* Override Bootstrap's form styling, because the Flatpickr input element is readonly even when the picker is active */
.form-control.flatpickr-form-control[readonly]:not([disabled]) {
  background-color: #fff;
}

.active-dates .form-control[readonly] {
  background-color: #fff;
}

.flatpickr-form-control {
  min-width: 220px;
}

.edit-room-rates {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #f8f8f8;
    }
  }
}

.field-array {
  .checkboxes {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 6px;

    .checkbox {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.group-margin-control {
  margin: 4px;
  padding-bottom: 7px;
}
