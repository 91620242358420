.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.list-unstyled {
  list-style: none;
  padding-left: 4px;
}

.offer-edit-form img {
  max-width: 100%;
}

.image-list-panel {
  .panel-body {
    padding: 0;
  }

  .image-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.modal-content {
  border: 0;
}

.modal-header {
  background-color: #f3f7fc;
  border-bottom: 0;

  h4 {
    font-weight: bold;
  }
}

.modal-footer {
  border-top: 0;
}

.icon-button {
  color: inherit;
  opacity: 0.5;

  &:hover,
  &:active {
    opacity: 1;
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: inherit;
  }
}

.icon {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.admin-user-roles-tab {
  ul {
    border: 0 !important;
  }

  a {
    border: 0 !important;
  }

  .active {
    font-weight: bold;
  }
}

.editable {
  border-bottom: 1px dashed #00a0f5;
}

.multiselect-zindex-9 .dropdown-content {
  z-index: 9 !important;
}
