.box-list-item {
  border: 1px solid #ddd;
  padding: 15px;

  & + & {
    border-top: 0;
  }
}

.add-button {
  margin-top: 22px;
}

.add-button::after {
  content: '+';
  margin-left: 12px;
}

.delete-all-button {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.right-float-button {
  float: right;
  padding: 4px 15px;
}

.inventory-sections {
  & > div + div {
    margin-top: 50px;
  }
}

.room-type-capacity-list {
  .room-type-capacity-row {
    border: 1px solid #ddd;
    padding: 3px;
    display: flex;
    flex-direction: row;

    button:first-of-type {
      margin-left: 5px;
    }
  }

  .room-type-capacity-row + .room-type-capacity-row {
    border-top: 0;
  }

  .room-type-capacity-age {
    margin: 2px;
    flex: 1;
    display: flex;
    background-color: #f5f6f7;
    padding: 0.7em 0.9em;
    align-items: center;

    .room-type-capacity-label {
      flex: 1;
      padding: 5px;
    }

    .room-type-capacity-amount {
      float: right;
    }
  }
}

.blackout-date-form,
.surcharge-date-form {
  .surcharge-date-range-container,
  .blackout-date-range-container {
    .right-float-button {
      padding: 0 15px;
    }

    &:first-child {
      margin-bottom: 5px;
      padding-right: 67px;
    }
  }

  .add-button {
    margin-top: 17px;
  }

  .blackout-range-container,
  .surcharge-range-container {
    margin-top: 5px;
    display: flex;

    .blackout-range-date,
    .surcharge-range-date {
      flex-grow: 1;

      .flatpickr-input {
        border: 0;
        background-color: #f1f3f5;
        box-shadow: none;
      }
    }

    .blackout-range-arrow,
    .surcharge-range-arrow {
      padding: 0 20px;
      text-align: center;
      font-size: 1.5em;
    }
  }

  .blackout-form-group,
  .surcharge-form-group {
    & .radio-inline {
      margin-right: 20px;
    }
  }

  .days-selector {
    display: flex;
    flex-direction: row;
    margin-top: 5px;

    &.hidden {
      display: none;
    }

    .day-box {
      border: 1px solid #ccc;
      border-right: 0;
      flex-grow: 1;
      text-align: center;
      padding: 10px 0;
      color: #ccc;

      &:last-child {
        border-right: 1px solid #ccc;
      }

      &.selected {
        background-color: $brand-primary;
        color: white;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  .cost-and-prices {
    margin-top: 40px;

    & > div {
      width: auto;
    }

    .cost.price-box {
      display: grid;
      grid-auto-columns: 100px 92px;
      grid-template-rows: 1fr;
      grid-auto-flow: column;
      grid-gap: 8px;
      align-items: center;
      width: 100%;
    }

    .price-box {
      margin-top: 4px;

      & > input {
        max-width: 100px;
        border-radius: 4px;
        border: 0;
        height: 38px;
        background-color: #f1f3f5;
        padding: 6px 12px;
        width: 7em;
      }

      select {
        margin-left: 4px;
        display: inline;
        border: 0;
        background-color: #f1f3f5;
        padding: 6px 12px;
        width: 5em;
        box-shadow: none;
      }
    }
  }
}

.capacity-form {
  width: 300px;

  input {
    border: 0;
    height: 36px;
    background-color: #f1f3f5;
    padding: 6px 12px;
    width: 7em;
  }

  .capacity-form-row {
    width: 260px;
  }

  .capacity-form-row + .capacity-form-row {
    margin-top: 10px;
  }
}

.look-disabled {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}
