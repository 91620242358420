/***** boundaries */

// Extra small screen / phone
$screen-xs-min: 480px;

// Small screen / tablet
$screen-sm-min: 768px;

// Medium screen / desktop
$screen-md-min: 992px;

// Large screen / wide desktop
$screen-lg-min: 1200px;
$screen-xl-min: 1500px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

@mixin breakpoint-up($width) {
  @if $width == 'sm' {
    @media (min-width: $screen-sm-min) { @content; }
  } @else if $width == 'md' {
    @media (min-width: $screen-md-min) { @content; }
  } @else if $width == 'lg' {
    @media (min-width: $screen-lg-min) { @content; }
  } @else if $width == 'xl' {
    @media (min-width: $screen-xl-min) { @content; }
  } @else {
    @media (min-width: $width) { @content; }
  }
}

@mixin breakpoint-down($width) {
  @if $width == 'xxs' {
    // TODO: extract as a variable
    @media (max-width: 320px) { @content; }
  } @else if $width == 'xs' {
    @media (max-width: $screen-xs-max) { @content; }
  } @else if $width == 'sm' {
    @media (max-width: $screen-sm-max) { @content; }
  } @else if $width == 'md' {
    @media (max-width: $screen-md-max) { @content; }
  } @else if $width == 'lg' {
    @media (max-width: $screen-lg-max) { @content; }
  } @else {
    @media (max-width: $width) { @content; }
  }
}

@mixin breakpoint-only($width) {
  @if $width == 'xs' {
    @media (max-width: $screen-xs-max) { @content; }
  } @else if $width == 'sm' {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
  } @else if $width == 'md' {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
  } @else if $width == 'lg' {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) { @content; }
  }
}
