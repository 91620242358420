.order-detail {
  .branded-panel {
    padding: 15px;
    word-wrap: break-word;
  }

  .items-panel-container .panel-body {
    padding: 0;

    .booking-item {
      border: none;
      border-bottom: 1px solid #edecec;
    }
  }

  .panel-heading {
    background-color: $gray-lighter;
  }

  .order-item {
    border-bottom: 1px solid #ededed;

    &:last-child {
      border-bottom: 0;
    }

    &.cancelled {
      color: #999;
    }

    .order-item-cell {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .gap-above {
    margin-top: 10px;
  }

  .align-right {
    float: right;
    margin-top: -30px;
  }

  .item-header {
    .item-name {
      font-size: 22px;
      font-weight: 600;
    }

    .item-info {
      color: #666;
      font-size: 14px;
      margin-top: 6px;
    }

    .surcharge-info {
      font-size: 14px;
      margin-top: 7px;
      color: #b1b1b1;
    }
  }

  .price-info {
    text-align: right;
    font-size: 16px;
    font-weight: 500;
  }

  .surcharge-info {
    font-size: 14px;
    color: #afafaf;
  }

  .glyphicon {
    color: $gray-light;
  }

  .glyphicon-warning {
    color: $warning;
  }

  .glyphicon-success {
    color: $success;
  }

  .bedbank-fineprint {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;

    .bedbank-fineprint-group {
      margin: 15px;
    }

    .bedbank-fineprint-group-name {
      font-weight: 700;
    }

    .bedbank-fineprint-value {
      ul {
        list-style: disc;
      }
    }
  }

  .bedbank-facilities {
    display: flex;
    flex-flow: row wrap;
    padding-left: 30px;
    padding-right: 30px;

    .bedbank-facility-group {
      margin: 15px;
      max-width: 250px;
    }

    .bedbank-facility-group-name {
      font-weight: 700;
    }
  }

  .item-offer,
  .item-reservation {
    margin-top: 30px;

    .section {
      margin-top: 5px;

      span.ended {
        color: red;
      }
    }

    .section-header {
      font-weight: 500;
      margin-bottom: 7px;
      display: flex;
    }

    .special-requests-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .special-requests-text {
      word-wrap: break-word;
    }

    .special-requests-input-group {
      width: 100%;
    }

    span {
      margin-right: 10px;
    }

    .insurance-subtitle {
      font-weight: bold;
      display: inline-block;
      width: 150px;

      span {
        font-weight: 500;
      }
    }

    .inclusions {
      margin-top: 30px;

      ul {
        list-style: disc;
      }
    }
  }

  .count {
    font-size: 2em;
    color: $gray-light;
  }

  .status-column {
    font-size: 16px;
    font-weight: 500;
    text-align: right;
  }

  .refund-column {
    font-weight: 500;
    text-align: right;
  }

  .link-button {
    padding: 0;
    font-size: 16px;
  }

  .order-item.book-later-select-dates {
    display: flex;
    padding: 0;

    .book-later-select-dates-summary {
      flex: 2;
      background-color: $gray-lighter;
      padding: 20px 35px;
    }

    .book-later-select-dates-main {
      flex: 5;
      padding: 20px 35px;
    }
  }

  .offer-details a {
    color: #00a0f5;
  }

  .order-header {
    display: flex;
    justify-content: space-between;

    strong {
      display: block;
    }

    .order-actions {
      display: flex;
      flex-direction: row;
    }

    .hotel-order-date {
      text-transform: none;
    }
  }

  .surcharge-waived-msg {
    font-weight: bold;
    color: $state-danger-text;
  }
}

.change-date-confirm-modal {
  .date-range {
    display: inline-block;
    margin-left: 12px;
    font-weight: 500;
    vertical-align: middle;
  }

  .guest-name {
    display: inline-block;
    margin-left: 12px;
    font-weight: 500;
    vertical-align: middle;
  }
}

.insurance-item-list {
  padding: 20px;
}

.order-addons-list {
  .order-addon {
    border-top: 1px solid #ededed;
  }
}

.type-heading {
  font-weight: 600;
}

.flight-details {
  margin-top: 12px;
}

.flight-traveller {
  border: 1px solid #ddd;
  padding: 20px;
  margin-top: 40px;
}

.order-search-form {
  padding: 5px 20px;
  margin-bottom: 15px;
  border-radius: 4px;
  background: #f9f9f9;

  .filter-container {
    margin-bottom: 0;
  }

  form [readonly],
  form [disabled] {
    pointer-events: fill;
  }
}

.download-btn {
  margin-bottom: 20px;
}

.gutter-left {
  margin-left: 10px;
}

.bundle-package-information {
  display: inline-block;
  border: 2px solid #333;
  padding: 4px;

  > *:last-child {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #ff4200;
  }
}

.service-fee-section {
  display: flex;
  justify-content: center;

  .left-col {
    flex-basis: 65%;
  }

  .right-col {
    flex-basis: 30%;
  }

  .service-fee-details {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .service-fee-heading {
    font-weight: bold;
  }

  .service-fee-column {
    display: flex;
    flex-direction: column;
  }

  .service-fee-row {
    display: flex;
    flex-direction: row;

    .service-fee-row-heading {
      flex-basis: 25%;
    }
  }

  .service-fee-outline {
    display: flex;
    flex-direction: column;
  }
}

html {
  scroll-behavior: smooth;
}
