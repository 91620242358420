.payment {
  .order-summary {
    background-color: white;
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    padding: 28px 37px;

    h2 {
      margin-top: 0;
      color: #ec734a;
      font-weight: 300;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .StripeElement {
    display: block;
    padding: 12px;
    border: 1px solid #d6d9da;
    background: white;
  }

  .order-summary-panel {
    border: 1px solid #d6d6d6;
    margin-top: 10px;

    .offer-meta {
      background-color: #ecefef;
      padding: 1px 14px 14px;
    }

    .offer-id {
      font-weight: 500;
    }
  }

  .summary-item-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .summary-item-item {
    padding: 24px 15px;

    &:not(:last-child) {
      border-bottom: 1px solid #d6d6d6;
    }

    .item-name {
      font-weight: 600;
      font-size: 20px;
      color: #000;
    }

    .item-number {
      font-size: 30px;
      font-weight: 600;
      color: #000;
      margin-right: 16px;
    }

    .item-detail-container {
      display: flex;
    }

    .item-detail {
      flex: 1;
    }
  }

  .item-amounts {
    text-align: right;

    .item-price {
      font-weight: 500;
      display: inline-block;
      font-size: 21px;
    }
  }

  .amount-row {
    padding: 13px 0;
    border-bottom: 1px solid #d6d6d6;

    &.grand-total-row {
      text-align: right;

      .amount {
        margin-left: 35px;
      }
    }

    &.breakdown-row {
      display: flex;
      justify-content: space-between;
    }

    .amount-type {
      font-size: 20px;
      font-weight: 500;
      display: inline-block;
    }

    .amount {
      font-weight: 500;
      display: inline-block;
      font-size: 18px;
    }
  }

  .price-inclusion-detail {
    text-align: right;
    margin-top: 10px;
    color: #797979;

    .price-inclusion-detail-header {
      text-transform: uppercase;
    }

    .price-inclusion-detail-type {
      vertical-align: middle;
    }

    .price-inclusion-detail-amount {
      margin-left: 10px;
      vertical-align: middle;
    }

    .price-inclusion-detail-row:not(:first-child) {
      margin-top: 4px;
    }
  }

  .item-reservation {
    margin-top: 11px;
    color: #585858;

    .glyphicon {
      margin-right: 7px;
    }

    .section:not(:first-child) {
      margin-top: 7px;
    }

    .section-header {
      font-weight: 500;
      margin-bottom: 7px;
    }

    .separator {
      margin: 0 10px;
    }
  }

  .spaced-label {
    margin-left: 4px;
  }

  .surcharge-waived-msg {
    font-weight: bold;
    color: $state-danger-text;
  }
}
