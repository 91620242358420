.icon-circle {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;

  &.primary {
    background-color: $brand-primary;
  }

  &.muted {
    background-color: $brand-muted;
  }

  &.success {
    background-color: $brand-success;
  }

  &.warning {
    background-color: $brand-warning;
  }
}
