// _shared.scss
// contains style definitions shared app-wide

.hidden {
  display: none;
}

.h-36 {
  height: 36px;
}

.modal-form-group {
  margin-top: 40px;
}

.blackout-form-group {
  margin-top: 65px;
}

.popover {
  z-index: 2000;
}

// for app running in a 'test' environment
.env-test {
  .navbar {
    opacity: 0.4 !important;
    border: 0 !important;
  }
}

html {
  font-weight: $default-font-weight;
}

body {
  overflow-y: scroll;

  .flatpickr-time {
    height: 40px;
  }
}

// booking-item
.booking-item {
  border: 2px solid rgba(237, 236, 236, 100%);
  margin-bottom: 9px;
}

.booking-item-content {
  display: flex;
}

.booking-item-meta {
  background-color: #ecefef;
  width: 380px;
  padding: 14px 20px 20px;
  display: flex;
  flex-direction: column;

  .item-number {
    font-size: 2em;
    font-weight: 600;
    color: #000;
  }

  .num-nights {
    font-size: 16px;
  }

  .package-name {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 600;
  }

  .item-price {
    font-size: 20px;
    font-weight: 500;
    margin-top: 2px;
  }
}

.booking-item-form-container {
  flex: 1;
  padding: 16px 18px;
}

.booking-item-footer {
  text-align: right;
  margin-top: 30px;
}
