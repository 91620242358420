.form-container {
  background-color: #f9f9f9;
  padding: 50px;
  border-radius: 5px;
}

.input-length {
  right: 15px;
  position: absolute;
  font-weight: 500;
  font-size: 16px;
}

.badges-form {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .form-container;

  padding: 25px;

  .drop-zone-panel {
    padding: 16px 0;
    background-color: #fff;
  }
}

.remove-badge-modal {
  display: flex;
  flex-direction: column;
}

.promo-form {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .form-container;

  .form-control {
    border-radius: 4px;
    border: #959595 1px solid;
    color: #4a4a4a;
  }

  .input-group-addon {
    background-color: #ccc;
    border: none;
  }

  .flight-deal-sub {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 60px;
  }
}

.templates-container {
  margin-top: 16px;

  form {
    .filter-group {
      margin-top: 8px;
      display: flex;

      :not(:last-child) {
        margin-right: 8px;
      }
    }

    .form-group {
      margin-top: 8px;

      :not(:last-child) {
        margin-bottom: 8px;
      }

      .form-label {
        margin-bottom: 4px;
      }

      .form-group-label {
        margin-bottom: 4px;
      }

      select {
        display: block;
        height: 32px;
        background-color: #fff;
        border: 1px solid #ccc;
      }

      .is-invalid {
        border: 1px solid orange;
        color: orange;
      }
    }

    .form-check {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .form-check-input {
        height: 28px;
        width: 28px;
        margin: 0 8px 0 0; //override
      }

      .form-check-label {
        margin-bottom: 0; //override
      }
    }
  }

  .search-bar {
    margin: 8px 0;
  }
}
