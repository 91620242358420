.button-link {
  .bar {
    font-size: 20px;
  }

  .select-link {
    padding: 4px 10px;
    cursor: pointer;
  }

  &.copy {
    margin-top: 30px;
    margin-left: 30px;

    a {
      pointer-events: none;
      color: #6c8996;
      cursor: default;
    }

    .active {
      color: #00a0f5;
      cursor: pointer;
      pointer-events: all;
      text-decoration: none;
    }
  }

  .control-label {
    margin-top: 4px;
  }
}

.fp-schedule-container,
.schedule-brands-container,
.schedule-type-container,
.schedule-return-btn,
.schedule-container {
  margin: 40px;
  padding-bottom: 40px;
}

.fp-container {
  transition: height 500ms 0ms, opacity 500ms 500ms;
  opacity: 1;

  &.hide {
    transition: height 500ms 500ms, opacity 500ms 0ms;
    opacity: 0;
    height: 0;
  }
}

.schedule-e2e-alert {
  margin: 0 40px;
}

.fp-schedule-inner {
  background-color: #eee;
  padding: 30px 50px;
  margin-top: 20px;

  .flatpickr-input {
    width: 250px;
  }

  .modal-regions-list,
  .modal-brands-list {
    margin-top: 30px;
    height: 300px;
    display: flex;
    flex-flow: column wrap;
    padding-left: 0;

    .checkbox-element-wrapper {
      padding: 8px 80px 8px 0;
    }
  }

  .action-buttons {
    .btn {
      width: 187px;
    }

    .btn-secondary {
      margin-right: 20px;
      background: #eee;
      border: 1px solid #00a0f5;
    }
  }
}

.fp-schedule-table {
  text-align: center;

  th {
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
  }

  th:first-child {
    min-width: 148px;
  }

  td {
    padding: 2px 0;
  }

  td + td,
  th + th {
    padding-left: 20px;
  }
}

.fp-schedule-buttons {
  margin-top: 20px;
  text-align: right;

  * + * {
    margin-left: 10px;
  }
}

.fp-schedule-edit {
  .flatpickr-input {
    width: 250px;
  }

  .modal-regions-list,
  .modal-brands-list {
    margin-top: 30px;
    height: 300px;
    display: flex;
    flex-flow: column wrap;
    padding-left: 0;

    .checkbox-element-wrapper {
      padding: 8px 60px 8px 0;
    }
  }
}

.lux-schedule-row {
  background-color: #eee;
  padding: 15px 30px;

  &.panel-title {
    font-size: 16px;
  }

  .date {
    display: flex;
    margin-top: 5px;
    margin-right: 30px;
  }

  .lux-schedule-regions {
    width: 800px;
  }

  .lux-schedule-date {
    display: flex;

    .date-labels {
      display: flex;
      font-weight: bolder;
      width: 330px;
    }
  }

  .lux-schedule-date-row {
    display: flex;

    .date {
      display: flex;
      margin-top: 5px;
      margin-right: 30px;
      width: 310px;
    }
  }

  a {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }
}

.fp-schedule-container {
  border-bottom: 1px solid gray;

  .panel-heading {
    padding: 0;
  }

  .panel,
  .panel-heading {
    border-radius: 0;
    border: none;
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
  }

  .panel-body {
    padding: 15px 0 0;
  }

  .select-link {
    color: #00a0f5;
    text-decoration: none;
  }

  .add-schedule {
    float: right;
    border-color: #008fdc;
  }
}

.schedule-form-base {
  display: flex;

  .schedules-arrow {
    text-align: center;
    font-size: 2em;
    margin: 20px 25px;
  }

  .schedules-arrow-short {
    text-align: center;
    font-size: 2em;
    line-height: 1em;
    margin: 0 15px;
  }
}

.schedule-form {
  display: flex;
  justify-content: space-between;

  button:disabled,
  button[disabled] {
    color: #666;
    background-color: #00a0f5;
  }

  .flatpickr-input {
    width: 250px;
  }

  .btn {
    margin-top: 27px;
    min-width: 112px;
  }

  .btn-success {
    background-color: transparent;
    border: none;
    color: gray;
  }

  .delete-btn {
    background-color: #f2aa1d;
  }

  .create-btn {
    background-color: #00a0f5;
    color: white;
  }

  .edit-btn {
    background-color: #6c8996;
    color: white;
  }

  .btn-default:hover,
  .btn-default {
    border: none;
  }
}

.schedule-form.filter-date {
  width: 750px;
}

.schedule-summary {
  display: flex;
  justify-content: space-between;

  .date {
    display: flex;

    .date-end {
      margin-right: 65px;
    }

    .date-labels {
      display: flex;
    }
  }

  .date-arrow {
    padding: 0 20px;
  }

  .empty {
    font-weight: bolder;
    margin-right: 65px;
  }
}

.schedule-pane {
  .lux-schedule-row {
    background-color: #eee;
    padding: 15px 30px;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;

    .arrow {
      padding: 0 20px;
    }

    .date {
      margin-top: 0;
      font-weight: bolder;
      width: 305px;
    }

    .title {
      font-weight: bolder;
    }

    .regions,
    .brands {
      width: 600px;
    }

    .fp-empty {
      margin-right: 40px;
    }
  }
}

.schedule-brands-container,
.schedule-type-container {
  background: aliceblue;
  padding: 50px;

  .schedule-brand-row {
    list-style-type: none;
    border-bottom: 1px solid gray;
    padding-bottom: 40px;

    &:last-child {
      border-bottom: none;
    }
  }
}

.schedules-error {
  color: red;
  margin-top: 30px;
  text-transform: capitalize;
}

.fp-visibility {
  margin-top: 30px;
}

.empty-regions {
  background-color: #eee;
  text-align: center;
  padding: 40px;
  position: relative;

  .close-button {
    position: relative;
    float: left;
    top: 0;
    right: 29px;
    margin-right: 20px;
    border: none;
    color: #00a0f5;
    background: transparent;
  }
}
