.timeline-item {
  padding: 25px 0;
  position: relative;
  margin-bottom: 0;

  &::before,
  &::after {
    content: '';
    display: block;
    background-color: $ui-gray;
    position: absolute;
  }

  &::after {
    content: '';
    top: 5px;
    right: 0;
    left: 0;
    height: 3px;
  }

  &::before {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    top: 0;
    left: 0;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  * {
    margin-bottom: 0;
  }
}

.with-side-bar {
  margin-left: 154px;
}

.timeline-wrapper {
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 25%;
  }
}
