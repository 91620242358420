.first-section {
  margin-top: 44px;
}

.branded-panel {
  h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    color: $title-highligts;
    margin-top: 0;

    a {
      line-height: 1;
    }

    .glyphicon {
      color: $title-edit-link;
    }
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span.branded-panel {
    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.panel-group {
  border-bottom: 1px solid $panel-inner-border;

  .panel {
    border-radius: 0;
    border-bottom: 0;

    & + .panel {
      border-top: 0;
      margin-top: 0;

      .panel-heading {
        border-top: 1px solid $panel-inner-border;
      }
    }
  }

  .panel-heading {
    padding: 0;
    min-height: 60px;

    + .panel-collapse > .panel-body {
      border-top: 0;
    }

    a {
      padding: $panel-heading-padding;
      display: block;
      cursor: pointer;
      position: relative;

      &::after {
        content: '−';
        color: $panel-default-border;
        font-size: 33px;
        position: absolute;
        font-weight: 300;
        right: 10px;
        top: 10px;
      }

      &.collapsed {
        &::after {
          content: '+';
        }
      }
    }
  }
}

.panel-heading {
  text-transform: uppercase;

  .page-header {
    text-transform: none;
    text-decoration: none;
  }
}

.panel-section + .panel-section {
  border-top: 1px solid #eee;
  margin-top: 13px;
}
