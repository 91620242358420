.show-hide-image {
  background: #53bc51;
  color: #eee;
  padding: 0.1rem 0.5rem;
  border-radius: 3px;

  &.image-hidden {
    background: rgb(98, 101, 94);
  }
}

.bedbank-img-responsive {
  width: auto;
  height: 100%;
}

.img-responsive.image-hidden {
  filter: grayscale(100%) blur(2px);
}
