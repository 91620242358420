/* stylelint-disable selector-class-pattern */

.le-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.le-modal-dialog {
  width: 600px;
  margin: 30px auto;
}

.insurance-refund-message {
  padding: 0 0 20px;
}

.ReactModal__Content > h1:first-child,
.ReactModal__Content > h2:first-child,
.ReactModal__Content > h3:first-child {
  margin-top: 0;
}
