.popular-destination,
.popular-region {
  margin: 40px 25px;
}

/* stylelint-disable-next-line selector-class-pattern */
.select-dropdown__control {
  height: 50px;
  margin-bottom: 30px;
}

.popular-destination-list {
  margin-top: 40px;
  display: flex;
  background: #adadad47;
  padding: 30px 20px;
}

.popular-destination-label {
  padding: 10px 40px;
  border-radius: 2px;
  margin-right: 20px;
  border: 1px solid gray;
  background: white;
  position: relative;
}

.popular-destination-delete {
  position: absolute;
  top: 4px;
  right: 5px;
  background: none;
  border: none;
}

.destination-panel {
  margin: 0 15px;

  .page-header {
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: none;
  }
}

.popular-destination-form {
  .hidden {
    display: none;
  }
}

.popular-destination {
  #type-selection {
    height: 53px;
    border-radius: 4px;
  }
}
