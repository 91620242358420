.new-order-page {
  .new-order-heading {
    display: inline-block;
    vertical-align: middle;
    font-size: 27px;
    margin: 0;
  }

  .new-order-header {
    position: relative;
    border-bottom: 1px solid #d6d6d6;
    padding: 20px 0 10px;
    margin-top: -22px;

    .cancel-btn {
      position: absolute;
      right: 32px;
      top: 6px;
      font-weight: 500;
    }

    .user-email {
      margin-left: 70px;
      vertical-align: middle;
      border: 1px solid #d4d4d4;
      padding: 5px 10px;
      color: #7d7d7d;
      border-radius: 2px;
    }
  }

  .new-order-body {
    padding-top: 24px;
    padding-bottom: 73px;

    &.payment {
      background-color: #f5f5f5;
    }
  }

  .new-order-step-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 17.5px;
  }

  .new-order-step-item {
    display: inline-block;
    font-weight: 500;

    &.inactive {
      color: #a9a9a9;
      font-weight: 300;
    }

    &:not(:first-child)::before {
      content: '>';
      margin: 0 19px;
    }
  }

  .new-order-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ecefef;
    border-top: 2px solid #f0f1f1;

    .back-section {
      width: 103px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e0e0e0;
      color: black;
      font-weight: 500;

      .text {
        vertical-align: middle;
        margin-left: 6px;
      }

      .fa {
        vertical-align: middle;
        font-size: 14px;
      }

      &:hover {
        background: #f5f5f5;
        text-decoration: none;
      }
    }

    .main-section {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .offer-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        color: #0094ff;
      }

      .meta {
        flex: 2;
        overflow: hidden;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .amounts {
        flex: 2;
        display: flex;
        justify-content: flex-end;
      }

      .action {
        flex: 1;
        display: flex;
        max-width: 25%;

        .btn {
          flex: 1;
        }
      }

      .amount {
        margin-right: 20px;

        .noun {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 600;
          color: #666;
          white-space: nowrap;
        }

        .value {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;

          > * + * {
            margin-left: 5px;
          }
        }
      }
    }
  }

  #currency-selection {
    display: inline-block;
  }

  .booking-information-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .remove-btn {
    color: #9a9a9a;

    &.disabled {
      cursor: not-allowed;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

// Not scoped to .new-order-page as modals live outside the react root

.date-booking-modal {
  .modal-title {
    font-size: 2em;
  }

  .modal-desc {
    margin-top: 8px;
  }

  .actions {
    text-align: right;

    .action ~ .action {
      margin-left: 10px;
    }
  }
}

// spceial-requests styles are shared with purchases page

.special-requests {
  width: 100%;

  .open-indicator {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #8e8e8e;
    display: inline-block;
    vertical-align: middle;
  }

  .toggle-text {
    vertical-align: middle;
    color: #8e8e8e;
    font-size: 14px;
  }

  &.is-open {
    .open-indicator {
      transform: rotate(90deg);
    }

    .special-requests-body {
      max-height: 10em;
    }
  }
}

.special-requests-toggle {
  color: inherit;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-weight: 500;
  margin-bottom: 8px;
}

.special-requests-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s;
}

.special-requests-input {
  width: 100%;
  border: 1px solid #e0e0e0;
  min-height: 91px;
  resize: none;
  padding: 8px 10px;

  &:focus {
    outline: none;
    border-color: #719ece;
  }
}
